
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* @keyframes mymove {
  0%  {width: 100px; justify-self: flex-start;}
  50% {width: 300px; justify-self: flex-end;}
  100%{width: 100px; justify-self: flex-start;}
} */

.progress {
  position: absolute;
  /* animation: fillAndEmpty 5s forwards; */
}
@keyframes fillAndEmpty {
  0% { left: 0; width: 0%; }
  50% { left: 0; width: 100%; }
  100% { left: 100%; width: 0%; }
}
.progress:hover {
  animation-play-state: paused;
}


.textFewww {
  /* transform: translateY(100%); */
}


.blackToTransparent{
  background: rgb(0,0,0);
  background: linear-gradient(90deg, rgba(0,0,0,0.7287289915966386) 28%, rgba(0,0,0,0) 70%);
}
.blackToTransparentReversed{
  background: rgb(0,0,0);
  background: linear-gradient(270deg, rgba(0,0,0,0.7287289915966386) 28%, rgba(0,0,0,0) 70%);
}
