@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'Tajawal';
  src: url("./Fonts/Tajawal/Tajawal-Regular.ttf") format('truetype');
}

@font-face {
  font-family: 'Manrope';
  src: url('./Fonts/Manrope/static/Manrope-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Krona';
  src: url('./Fonts/KronaOne-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Jost';
  src: url('./Fonts/Jost-Regular.ttf') format('truetype');
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.mapBox {
  background: "red";
  border: "1px solid";
  max-width: "250px";
  min-height: "100px";
}
@media (max-width:768px){ 
  .jvectormap-container {
    height:300px !important; 
    width:100%;
  }
}


.outOfView{
  opacity: 0;
  transform: translateY(35%);
  transition: all 1s;
}

.show{
  opacity: 1;
  transform: translateX(0);
}

@media(max-width:768px){
  .outOfView{
    opacity: 0;
    transform: translateY(20%);
    transition: all 1s;
  }
  
.show{
  opacity: 1;
  transform: translateX(0);
}
}


.outleene{
  /* color: #2B2C32; Text color */
  text-shadow: 
    -1px -1px 0 #fff,  
     1px -1px 0 #fff,
    -1px  1px 0 #fff,
     1px  1px 0 #fff; 
}



/* PDF DOWNLOAD BUTTON */
.button1 {
  padding: 0.8rem 4rem;
  border: none;
  outline: none;
  font-size: 1.3rem;
  border-radius: 0.3rem;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: 0.4s ease-in-out;
}

.button1 .text1 {
  transition: 0.4s ease-in-out;
}

.svg1 {
  transform: translateY(-20px) rotate(30deg);
  opacity: 0;
  width: 2rem;
  transition: 0.4s ease-in-out;
}


.button1:hover .svg1 {
  display: inline-block;
  transform: translateY(0px) rotate(0deg);
  opacity: 1;
}

.button1:hover .text1 {
  opacity: 0;
}

.button1:active {
  scale: 0.97;
}